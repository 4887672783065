import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import { StrapiSession } from '../../../../../types';

@Component({
    selector: 'standard-session-moderator',
    templateUrl: './session-moderator.component.html',
    styleUrls: ['./session-moderator.component.scss'],
})
export class SessionModeratorComponent implements OnInit, OnDestroy {
    @Input() session: StrapiSession;
    @Output() toolsToggled = new EventEmitter<string>();

    constructor() {}

    ngOnInit() {}

    toggle(tool: any) {
        if (tool === 'active' && this.session.state.active) {
            if (!confirm('This will end the session for all participants')) {
                return;
            }
        }
        this.toolsToggled.emit(tool);
    }

    ngOnDestroy() {}
}
