<div class="paginator">
    <button type="button"
        (click)="previous()"
        [disabled]="currentPage <= 0"
    >
        previous
    </button>
    <div class="dot"
        *ngFor="let dot of dots"
        [ngClass]="dot == currentPage && 'large-dot'"
    >
    </div>
    <button type="button"
        (click)="next()"
        [disabled]="currentPage * limit > count - limit - 1"
    >
        next
    </button>
</div>
