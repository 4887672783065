<div id="navigation">
  <div class="">
    <img src="https://visionmaps.io/wp-content/uploads/2024/04/image-17.png" height="75" />
  </div>
</div>

<div
  class="header-middle"
  [ngClass]="!closeNotificationPopUp && notification ? 'extra-bottom' : ''"
>
  <h1>VISIONMAP DEMO</h1>
</div>

<div class="">
  <app-login></app-login>
</div>
