import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ConstellationSession,
    GovernanceSession,
    PlenarySession,
    SocialSession,
    Status,
    StrapiSession,
    WgSession,
} from '../../types';
import { tap, map } from 'rxjs/operators';
import { SocketClient } from 'src/app/socket/socket.client';

type SessionByKind = {
    wg: WgSession;
    governance: GovernanceSession;
    constellation: ConstellationSession;
    social: SocialSession;
};

@Injectable()
export class SessionService {
    constructor(private _http: HttpClient, private _socket: SocketClient) {}

    locationNameMap = {
        WG_Africa: 'The Africa Working Group Ship',
        WG_Behavior_and_Society: 'The Behavior and Society Working Group Ship',
        WG_Complexity_Economics: 'The Complexity Economics Working Group Ship',
        WG_Cooperatives: 'The Cooperatives Working Group Ship',
        WG_East_Asia: 'The East Asia Working Group Ship',
        WG_Economic_Development: 'The Economic Development Working Group Ship',
        WG_Economic_History: 'The Economic History Working Group Ship',
        WG_Economics_of_Innovation:
            'The Economics of Innovation Working Group Ship',
        WG_Finance_Law_and_Economics:
            'The Finance, Law, and Economics Working Group Ship',
        WG_Financial_Stability: 'The Financial Stability Working Group Ship',
        WG_Gender_and_Economics: 'The Gender and Economics Working Group Ship',
        WG_History_of_Economic_Thought:
            'The History of Economic Thought Working Group Ship',
        WG_Inequality: 'The Inequality Working Group Ship',
        WG_Keynesian_Economics: 'The Keynesian Economics Working Group Ship',
        WG_Keynesian_Economics_Working_Group:
            'The Keynesian Economics Working Group Ship',
        WG_Latin_America: 'The Latin America Working Group Ship',
        WG_Philosophy_of_Economics:
            'The Philosophy of Economics Working Group Ship',
        WG_Political_Economy_of_Europe:
            'The Political Economy of Europe Working Group Ship',
        WG_South_Asia: 'The South Asia Working Group Ship',
        WG_States_and_Markets: 'The States and Markets Working Group Ship',
        WG_Sustainability: 'The Sustainability Working Group Ship',
        WG_Urban_and_Regional_Economics:
            'The Urban and Regional Economics Working Group Ship',
        Constellation_Development: 'Constellation - Development',
        Constellation_Inequalities: 'Constellation - Inequalities',
        Constellation_Innovation: 'Constellation - Innovation',
        Constellation_Globalization: 'Constellation - Globalization',
        Constellation_Sustainability: 'Constellation - Sustainability',
        Constellation_Future_of_Money: 'Constellation - Future of Money',
        Constellation_The_Role_of_Economists:
            'Constellation - The Role of Economists',
        Constellation_Economic_Visions: 'Constellation - Economic Visions',
        Constellation_Societal_Organization:
            'Constellation - Societal Organization',
        Constellation_Digital_Economy: 'Constellation - Digital Economy',
        Constellation_Democracy_and_Governance:
            'Constellation - Democracy and Governance',
    };

    enterSession(sessionId: string) {
        this._socket.emit('enterSession', sessionId);
        this._http
            .get(`/plenary-sessions/session/${sessionId}/joinMatrixRoom`)
            .subscribe();
    }

    exitSession() {
        this._socket.emit('exitSession', null);
    }

    reconnect() {
        return this._socket.on('reconnect');
    }

    session() {
        return this._socket.on('session');
    }

    sessionState() {
        return this._socket.on('sessionState');
    }

    updatedQuestions(status: Status) {
        return this._socket.emit('update', status);
    }

    pullQuestions() {
        return this._socket
            .on('pullNewQuestion')
            .pipe(tap((u) => console.log(u)));
    }

    getSessions<K extends keyof SessionByKind>(kind: K) {
        return this._http
            .get<(SessionByKind[K] & StrapiSession)[]>('/plenary-sessions', {
                params: { kind },
            })
            .pipe(
                map((sessions) =>
                    sessions.sort(
                        (a, b) =>
                            new Date(a.start_time).getTime() -
                            new Date(b.start_time).getTime()
                    )
                )
            );
    }

    getSessionsForWg(wgId: string) {
        return this._http
            .get<(WgSession & StrapiSession)[]>(`/plenary-sessions/wg/${wgId}`)
            .pipe(
                map((sessions) =>
                    sessions.sort(
                        (a, b) =>
                            new Date(a.start_time).getTime() -
                            new Date(b.start_time).getTime()
                    )
                )
            );
    }

    getSessionsForConstellation(constellationId: string) {
        return this._http.get<any[]>(
            `/plenary-sessions/space/${constellationId}`
        );
    }

    getSession(id: string) {
        return this._http.get<PlenarySession & StrapiSession>(
            `/plenary-sessions/session/${id}`
        );
    }

    getLatestSession() {
        return this._http.get<PlenarySession & StrapiSession>(
            '/plenary-sessions/latest'
        );
    }

    happeningToday() {
        return this._http.get<PlenarySession & StrapiSession>(
            '/plenary-sessions/happening-today'
        );
    }

    activeNow() {
        return this._http.get<PlenarySession & StrapiSession>(
            '/plenary-sessions/active-now'
        );
    }

    featuredUpcoming() {
        return this._http.get<PlenarySession & StrapiSession>(
            '/plenary-sessions/featured-upcoming'
        );
    }

    createSession(session: any) {
        return this._http.post<any>('/plenary-sessions', session);
    }

    uploadFilesToSession(files: File[], id: string) {
        console.log('files:', files);

        return this._http.patch<any>(`/plenary-sessions/${id}`, files);
    }

    attendSession(id: string) {
        return this._http.get<any>(`/plenary-sessions/session/${id}/attend`);
    }

    unattendSession(id: string) {
        return this._http.get<any>(`/plenary-sessions/session/${id}/unattend`);
    }

    getAttendeeSessions(id: string) {
        return this._http
            .get<any>(`/plenary-sessions/attendee/${id}`)
            .pipe(
                map((sessions) =>
                    sessions.sort(
                        (a, b) =>
                            new Date(a.start_time).getTime() -
                            new Date(b.start_time).getTime()
                    )
                )
            );
    }
}
