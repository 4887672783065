import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceWithDots',
})
export class SliceWithDotsPipe implements PipeTransform {
  transform(str: string, length: number): string {
    if (str?.length >= length) {
      return str.substring(0, length) + '...';
    } else return str;
  }
}
