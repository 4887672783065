<div [hidden]="!modalActive" class="modal">
  <div class="modal-content">
    <a [routerLink]="[{outlets:{modal:null}}]" class="float-right">
        <i class="fa fa-times" title="dismiss"></i>
    </a>

    <router-outlet
      name="modal"
      (activate)="onModalActivate()"
      (deactivate)="onModalDeactivate()"
    ></router-outlet>
  </div>
</div>
<div class="contain"
     [ngClass]="modalActive && 'ysi-modal-active'"
>
    <app-header></app-header>

    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>
