import { Injectable } from '@angular/core';
import { SocketClient } from 'src/app/socket/socket.client';

@Injectable({ providedIn: 'root' })
export class SessionModerationService {
  constructor(
    private _socket: SocketClient,
  ) { }

  moderatorToggle(t: { [tool: string]: boolean }) {
    this._socket.emit('moderatorToggle', t, 'moderator');
  }
}
