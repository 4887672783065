import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'chatUrl'
})
export class ChatUrlPipe implements PipeTransform {

  constructor(
    private _sanitizer: DomSanitizer,
  ) { }

  transform(roomId: string) {
    return this._sanitizer
      .bypassSecurityTrustResourceUrl(`https://chat.plenary.foxlab.io/`);
  }

}
