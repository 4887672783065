import { Pipe, PipeTransform } from '@angular/core';
import { StrapiSession } from '../../types';

@Pipe({
    name: 'sessionUrlPrefix',
})
export class SessionUrlPrefixPipe implements PipeTransform {
    transform(session: StrapiSession): string {
        const wgUrl = (session: StrapiSession) =>
            session.Location.split('_').slice(1).join('-').toLowerCase();

        const sessionUrlMap = {
            WG: `/wg/${wgUrl(session)}`,
            Constellation: '/questions',
            Community: '/community',
            Social: '/social-island',
        };

        const parsedLocation = session.Location.split('_')[0];

        return sessionUrlMap[parsedLocation];
    }
}
