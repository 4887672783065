import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { NgxsModule } from '@ngxs/store';
import { KeycloakAngularModule } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { APIInterceptor } from './api.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthState } from './auth/store/auth.store';

import { FormComponent } from './form/form.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './registration/login/login.component';
import { SessionsModule } from './sessions/sessions.module';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        LoginComponent,
        FormComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        KeycloakAngularModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxsModule.forRoot([AuthState], {
            developmentMode: !environment.production,
        }),
        AppRoutingModule,
        FormlyModule.forRoot({
            validationMessages: [
                { name: 'required', message: 'This field is required' },
            ],
        }),
        FormlyBootstrapModule,
        SessionsModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
