import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constellation } from '../models/constellation.model';
import { QuestionStateService } from './question.state.service';
import { filter, tap } from 'rxjs/operators';
import { Phrase, Question } from '../models/question.model';

import {
    GraphNode,
    GraphLink,
} from '../questions-home/questions-graph/questions-graph-core/constellation-to-graph-mapper.d';
import { Store } from '@ngxs/store';
import { AuthAction } from 'src/app/auth/store/auth.actions';
import { SessionService } from 'src/app/sessions/session.service';

const debug: boolean = true;

export type ConstellationsWithMD5 = {
    constellations: Constellation[];
    md5sum: string;
};

@Injectable({ providedIn: 'root' })
export class ConstellationHttpService {
    constructor(
        private _http: HttpClient,
        private _questionState: QuestionStateService,
        private _store: Store,
        private _sessionService: SessionService
    ) {}

    public CurrenMD5 = '';

    // private md5Check(c: ConstellationsWithMD5) {
    // 	return [
    // 		// filter(c => c.md5sum != this.CurrenMD5),
    // 		// tap((c) => {
    // 		// 	this.CurrenMD5 = c.md5sum;
    // 		// 	this._questionState.constellations = c.constellations;
    // 		// })
    // 	]
    // }

    getAll() {
        if (debug) {
            console.log('Pull new data');
        }
        return this._http.get<ConstellationsWithMD5>(`/constellation/all`).pipe(
            filter((c) => {
                if (debug) {
                    console.log('...testing MD5:');
                }
                {
                    if (debug) {
                        console.log(
                            c.md5sum != this.CurrenMD5
                                ? "It's different"
                                : "It's identical - not updating constellations!"
                        );
                    }
                    return true; //c.md5sum != this.CurrenMD5;
                }
            }),
            tap((c) => {
                this.CurrenMD5 = c.md5sum;
                console.log('getAll - MD5', this.CurrenMD5);
            })
        );
    }

    getMappedQuestion() {
        return this._http.get<any[]>('/constellation/questions/mapped');
    }

    questionFromQuestionID(constellation: Constellation, questionId: string) {
        return constellation.questions.find(
            (question) => question._id == questionId
        );
    }

    /// TODO TEST
    updateVote(phrasingId: string, voted: boolean, question: Question) {
        if (debug) {
            console.group('Vote');
            console.time('Update Vote on server');
        }
        const body = {
            phrasingId: phrasingId,
            voted,
        };
        // if (debug)
        // 	console.debug(
        // 		`OK, talking to the API and sending this: {phrasingId:${body.phrasingId}, voted:${body.voted}}`
        // 	);
        return this._http
            .put<ConstellationsWithMD5>(`/constellation/vote`, body)
            .pipe(
                filter((c) => {
                    if (debug) {
                        console.timeEnd('Update Vote on server');
                        console.groupEnd();
                    }
                    return c.md5sum != this.CurrenMD5;
                }),
                tap((c) => {
                    this.CurrenMD5 = c.md5sum;
                    this._questionState.constellations = c.constellations;
                })
            );
    }

    /// TODO TEST
    addPhrasing(questionId: string, phrasingText: string) {
        const body = {
            questionId,
            phrase: phrasingText,
        };
        return this._http
            .put<ConstellationsWithMD5>(`/constellation/phrase`, body)
            .pipe(
                filter((c) => c.md5sum != this.CurrenMD5),
                tap((c) => {
                    this.CurrenMD5 = c.md5sum;
                    this._questionState.constellations = c.constellations;
                })
            );
    }

    /// TODO TEST
    movePhrasing(destinationQuestionId: string, phrasing: Phrase) {
        const body = {
            destinationQuestionId,
            phrase: phrasing,
        };

        if (debug) console.debug('about to send phrase to move: ', body);
        return this._http
            .put<ConstellationsWithMD5>(`/constellation/move`, body)
            .pipe(
                filter((c) => c.md5sum != this.CurrenMD5),
                tap((c) => {
                    this.CurrenMD5 = c.md5sum;
                    this._questionState.constellations = c.constellations;
                })
                // if (debug) console.debug('phrasing moved: ', updatedConstellation);
                // const question = this.questionFromQuestionID(
                // 	updatedConstellation,
                // 	destinationQuestionId
                // );
                // this._sessionService.updatedQuestions(question.status);
                // this._questionState.updateConstellation(updatedConstellation);
                // })
            );
    }

    // TODO TEST
    deletePhrasing(phrasingId: string, question: Question) {
        if (debug)
            console.debug(
                `OK, talking to the API and sending this: {PhrasingId:"${phrasingId}}"`
            );
        ///alert('delete phrasing needs API endpoint');
        return this._http
            .delete<ConstellationsWithMD5>(
                `/constellation/phrase/${phrasingId}`
            )
            .pipe(
                filter((c) => c.md5sum != this.CurrenMD5),
                tap((c) => {
                    this.CurrenMD5 = c.md5sum;
                    this._questionState.constellations = c.constellations;
                })
                // tap((updatedConstellation) => {
                // 	if (debug)
                // 		console.debug('Updated Constellation:', updatedConstellation);
                // 	this._sessionService.updatedQuestions(question.status);
                // 	this._questionState.updateConstellation(updatedConstellation);
                // })
            );
    }

    /// TODO TEST
    updatePhrasing(phrasingId: string, phrasing: string, question: Question) {
        const body = { phrasing };
        if (debug)
            console.debug(
                `OK, talking to the API and sending this: {PhrasingId:"${phrasingId}}"`
            );
        return this._http
            .put<ConstellationsWithMD5>(
                `/constellation/phrase/${phrasingId}`,
                body
            )
            .pipe(
                filter((c) => c.md5sum != this.CurrenMD5),
                tap((c) => {
                    this.CurrenMD5 = c.md5sum;
                    this._questionState.constellations = c.constellations;
                })
            );
    }

    /// TODO TEST
    createQuestion(constellationId: string, question: Question) {
        const body = {
            constellationId,
            question,
        };
        if (debug) console.debug('about to send new question: ', question);
        return this._http
            .post<ConstellationsWithMD5>(`/constellation/question`, body)
            .pipe(
                filter((c) => c.md5sum != this.CurrenMD5),
                tap((c) => {
                    this.CurrenMD5 = c.md5sum;
                    this._questionState.constellations = c.constellations;
                })
                // tap((c) => {
                // 	console.log('Svar fra serveren: ', c);
                // 	const q = c.questions[c.questions.length - 1];
                // 	if (
                // 		!q.question ||
                // 		q.researchInterests.length < 1 ||
                // 		q.phrasings.length < 1
                // 	)
                // 		console.error('BAD QUESTION CREATED', q);
                // 	if (debug) console.debug('Add-question result:', q);
                // 	this._sessionService.updatedQuestions(question.status);
                // 	this._questionState.updateConstellation(c);
                // })
            );
    }

    /// TODO TEST
    updateQuestion(question: Question) {
        const body = {
            question,
        };
        if (debug) console.debug('about to send question to update: ', body);
        return this._http
            .put<ConstellationsWithMD5>(`/constellation/question`, body)
            .pipe(
                filter((c) => c.md5sum != this.CurrenMD5),
                tap((c) => {
                    this.CurrenMD5 = c.md5sum;
                    this._questionState.constellations = c.constellations;
                })
                // tap((updatedConstellation) => {
                // 	console.log('updatedConstellation: ', updatedConstellation);

                // 	if (debug)
                // 		console.debug('Constellation updated:', updatedConstellation);
                // 	this._sessionService.updatedQuestions(question.status);
                // 	this._questionState.updateConstellation(updatedConstellation);
                // })
            );
    }

    // Dennis may need this later
    // toggleActive(active: boolean) {
    //   return this._http.post('/constellation/toggle-active', { active });
    // }

    // TODO test
    toggleActive(questionId: string, sessionId: string) {
        return this._http
            .put<ConstellationsWithMD5>('/constellation/question/activate', {
                questionId,
                sessionId,
            })
            .pipe(
                filter((c) => c.md5sum != this.CurrenMD5),
                tap((c) => {
                    this.CurrenMD5 = c.md5sum;
                    this._questionState.constellations = c.constellations;
                })
                // tap((updatedConstellation) => {
                // 	if (debug)
                // 		console.debug('Updated Constellation:', updatedConstellation);
                // 	const question = this.questionFromQuestionID(
                // 		updatedConstellation,
                // 		questionId
                // 	);
                // 	this._sessionService.updatedQuestions(question.status);
                // 	this._questionState.updateConstellation(updatedConstellation);
                // })
            );
    }

    /// TODO TEST
    publishQuestion(questionId: string) {
        return this._http
            .put<ConstellationsWithMD5>('/constellation/question/publish', {
                questionId,
            })
            .pipe(
                filter((c) => c.md5sum != this.CurrenMD5),
                tap((c) => {
                    this.CurrenMD5 = c.md5sum;
                    this._questionState.constellations = c.constellations;
                })
                // tap((updatedConstellation) => {
                // 	if (debug)
                // 		console.debug('Updated Constellation:', updatedConstellation);
                // 	const question = this.questionFromQuestionID(
                // 		updatedConstellation,
                // 		questionId
                // 	);
                // 	this._sessionService.updatedQuestions(question.status);
                // 	this._questionState.updateConstellation(updatedConstellation);
                // })
            );
    }

    /// Other quickies
    saveGraphSnapshot(graphData: { nodes: GraphNode[]; links: GraphLink[] }) {
        const body = {
            graphData,
        };
        if (debug) console.debug('About to send graph data to server: ', body);

        return this._http.put<string>(`/graph`, body).pipe(
            tap((message) => {
                console.log(message);
            })
        );
    }

    addFavorite(questionId: string) {
        const body = {
            question: questionId,
        };
        if (debug) console.debug('About to send question to server: ', body);
        return this._http.post<Question>(`/plenary/add-question`, body).pipe(
            tap((question) => {
                if (debug) console.debug('Updated Question:', question);
                this._store.dispatch(new AuthAction.GetAuthorizedMember());
                this._sessionService.updatedQuestions(question.status);
                //this._questionState.updateConstellation(question);
            })
        );
    }
}
