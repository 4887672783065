import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { from, Observable, of } from 'rxjs';

import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface IMember {
    _id: string;
    firstname: string;
    lastname: string;
    fullname: string;
    researchInterest: string[];
    role: {
        name: string;
        value: number;
    };
    isDeleted: boolean;
    auth?: any;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _token: string;
    get token() {
        if (this._token) {
            return this._token;
        }
        return isPlatformBrowser(this._platformId)
            ? window.localStorage.accessToken
            : null;
    }

    private _bearerToken: string;
    get bearerToken(): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            if (this._bearerToken) resolve(this._bearerToken);
            const token = await this._keycloakService.getToken();
            return token;
        });
    }

    constructor(
        private _http: HttpClient,
        private _keycloakService: KeycloakService,
        private _router: Router,
        @Inject(PLATFORM_ID) private _platformId: string
    ) {}

    login() {
        // //this is where we're sent back after api callback
        // const redirectUrl = encodeURIComponent(window.location.origin + this._router.url);
        // // console.log('redirectUrl: ', redirectUrl);

        // // this is where we're sent immediately after a keycloak login
        // const redirectUri = `${environment.api}/keycloak/callback?redirectUrl=${redirectUrl}`;
        // // console.log('redirectUri: ', redirectUri);

        //this._keyklokService.login({
        //  redirectUri,
        //});
        alert('login!');
    }

    logout() {
        return from(this._keycloakService.logout());
    }

    getMember(keycloakMember: any): Observable<IMember> {
        // return this._http
        //     .post('/keycloak/member', { id: keycloakMember.attributes.id[0] })
        //     .pipe(
        //         map((m) => {
        //             return { ...m, auth: keycloakMember };
        //         })
        //     );

        const member = {
            _id: '42',
            firstname: 'User',
            lastname: 'Userson',
            fullname: 'User Userson',
            researchInterest: [],
            role: {
                name: 'moderator',
                value: 43,
            },
            isDeleted: false,
        };

        return of({ ...member, auth: keycloakMember });
    }

    getAuthorizedMember(): Observable<any | null> {
        const getAuthKeyCloak = async () => {
            const isLoggedIn = await this._keycloakService.isLoggedIn();

            if (!isLoggedIn) {
                return null;
            }

            const profile = await this._keycloakService.loadUserProfile();

            const roles = this._keycloakService.getUserRoles();

            const profileWithRoles = { ...profile, roles };

            return profileWithRoles;
        };

        const profileWithRoles = {
            roles: ['eldermod', 'moderator'],
            id: '42',
            username: 'User42',
            email: 'user42@g.c',
            firstName: 'User',
            lastName: 'Userson',
            enabled: true,
            emailVerified: true,
        };

        // return from(getAuthKeyCloak()).pipe(
        //     filter((m) => m != null),
        //     mergeMap((id) => this.getMember(id))
        // );
        const member: Observable<any | null> = new Observable();

        return this.getMember(profileWithRoles);
    }
}
