import { Injectable } from '@angular/core';
import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';
import { PlenarySession, StrapiSession } from '../../../types';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { AuthAction } from './auth.actions';

// remember to replace 'any' with 'member' sometime
export type AuthStateModel = any | null;

@State<AuthStateModel>({
    name: 'auth',
    defaults: null,
})
@Injectable()
export class AuthState {
    @Selector()
    static isAuthenticated(state: AuthStateModel) {
        return !!state;
    }

    @Selector()
    static isModerator(state: AuthStateModel) {
        return (<string[]>state.auth.roles).includes('moderator');
    }

    @Selector()
    static isProducer(state: AuthStateModel) {
        return (<string[]>state.auth.roles).includes('producer');
    }

    static isModeratorIn(session: PlenarySession & StrapiSession) {
        return createSelector([AuthState], (state: AuthStateModel) => {
            if (state.auth.roles.includes('eldermod')) return true;

            const mods = session.moderators.map((m) => m.memberId);

            return (
                state.auth.roles.includes('moderator') &&
                mods.includes(state._id)
            );
        });
    }

    constructor(private authService: AuthService) {}

    @Action(AuthAction.Login)
    login(ctx: StateContext<AuthStateModel>, action: AuthAction.Login) {
        return this.authService.login();
    }

    @Action(AuthAction.Logout)
    logout(ctx: StateContext<AuthStateModel>) {
        return this.authService.logout().pipe(
            tap(() => {
                ctx.setState(null);
            })
        );
    }

    @Action(AuthAction.GetAuthorizedMember)
    getAuthorizedMember(ctx: StateContext<AuthStateModel>) {
        return this.authService.getAuthorizedMember().pipe(
            tap((r) => {
                console.log('auth member', r);
                ctx.setState(r);
            })
        );
    }
}
