import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./questions/questions.module').then(
                (m) => m.QuestionsModule
            ),
    },
    // {
    // 	path: '*',
    // 	loadChildren: () =>
    // 		import('./main-hub/main-hub.module').then((m) => m.MainHubModule),
    // },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
