import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CmsService } from 'src/app/cms/cms.service';
import { SocketClient } from 'src/app/socket/socket.client';
import { Converter } from 'showdown';
import { Page } from '../questions/models/page.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    showSupport: boolean = false;
    closeNotificationPopUp = false;

    converter = new Converter();

    notification: string;
    notification$: Observable<string>;

    constructor(private _cmsService: CmsService) {}

    ngOnInit() {
        this._cmsService
            .getSingle<Page>('notification')
            .pipe(
                map((page: any) => this.converter.makeHtml(page.Notification))
            )
            .subscribe((n) => {
                this.notification = n;
            });
    }
    toggleSupport() {
        this.showSupport = !this.showSupport;
    }
    closeNotification() {
        this.closeNotificationPopUp = !this.closeNotificationPopUp;
    }
}
