import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PlenarySession, StrapiSession } from '../../../../types';
import { Observable, Subscription } from 'rxjs';
import { AuthAction } from 'src/app/auth/store/auth.actions';
import { AuthState } from 'src/app/auth/store/auth.store';
import { SessionModerationService } from '../../session-moderation.service';
import { SessionService } from '../../session.service';

@Component({
    selector: 'standard-session',
    templateUrl: './standard-session.component.html',
    styleUrls: ['./standard-session.component.scss', '../../../base.scss'],
})
export class StandardSessionComponent implements OnInit, OnDestroy, OnChanges {
    _session: StrapiSession & PlenarySession;

    get session() {
        return this._session;
    }

    @Input()
    set session(session: any) {
        this._session = session;

        this.isModerator$ = this._authStore.select(
            AuthState.isModeratorIn(this.session)
        );
    }

    showSessionData = true;
    subs: Subscription[] = [];
    joined = false;
    mod = false;

    isModerator$: Observable<boolean>;

    @Select(AuthState.isAuthenticated)
    member$: Observable<any>;
    isAuthenticated = false;

    locationNameMap = this._sessionService.locationNameMap;

    constructor(
        private _moderationService: SessionModerationService,
        private _sessionService: SessionService,
        private _authStore: Store
    ) {}

    ngOnInit() {
        this.isModerator$ = this._authStore.select(
            AuthState.isModeratorIn(this.session)
        );

        this.subs.push(
            this.isModerator$.subscribe((m) => {
                this.mod = m;
            })
        );

        this.subs.push(
            this._sessionService.sessionState().subscribe((s) => {
                this.session.state = s;
                if (!s.active) {
                    this.joined = false;
                }
            })
        );

        this.member$.subscribe(
            (isAuthenticated) => (this.isAuthenticated = isAuthenticated)
        );
    }

    toggleSessionData() {
        this.showSessionData = !this.showSessionData;
    }

    onToolToggled(tool: keyof any) {
        this.session.state[tool] = !this.session.state[tool];
        this._moderationService.moderatorToggle({
            [tool]: this.session.state[tool],
        });
        this.session = Object.assign({}, this.session);
    }

    join() {
        this.joined = !this.joined;
        this.onToolToggled('isVisible');
    }

    login() {
        this._authStore.dispatch(new AuthAction.Login()).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.session.previousValue) {
            if (
                changes.session.currentValue._id !==
                changes.session.previousValue._id
            ) {
                this.joined = false;
            }
        }
    }

    ngOnDestroy() {
        this.subs.forEach((s) => s.unsubscribe());
    }
}
