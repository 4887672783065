import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { CmsService } from 'src/app/cms/cms.service';

interface StrapiForm {
  name: string;
  submitButtonText: string;
  questions: [
    {
      __component: string;
      question: {
        question: string;
        uniqueKey: string;
      };
      config: {
        placeholder: string;
        description: string;
        required: boolean;
      };
      options?: [];
      selectMultiple?: boolean;
      minimum?: number;
      maximum?: number;
      areaHeight?: number;
    }
  ];
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  allForms: StrapiForm[];
  strapiForm: StrapiForm;
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  constructor(private cmsService: CmsService) {}

  ngOnInit() {
    this.cmsService.getSingle<StrapiForm[]>('forms').subscribe(strapiForms => {
      this.allForms = strapiForms;
      this.strapiForm = strapiForms[0]; // Since forms is a collection type, strapiForms is an array of forms. We have to find another solution so we can get rid of this hardcoding. If we want specific forms at specific sites in the application we need to link the form and site together in some way...
      this.model = Object.assign(
        {},
        ...this.strapiForm.questions.map(item => ({
          [item.question.uniqueKey]:
            item.__component.split('.')[1] === 'number' ? null : '' // Set null on the model property if the input type is number. Else set it as an emty string
        }))
      );
      this.fields = this.strapiForm.questions.map(item => {
        let ffc: FormlyFieldConfig = {};
        ffc.key = item.question.uniqueKey;
        ffc.type = item.__component.split('.')[1];
        if (ffc.type === 'text' || ffc.type === 'number') ffc.type = 'input';
        ffc.templateOptions = {
          label: item.question.question,
          type: item.__component.split('.')[1],
          min: item.minimum,
          max: item.maximum,
          rows: item.areaHeight,
          placeholder: item.config.placeholder,
          description: item.config.description,
          required: item.config.required,
          multiple: item.selectMultiple,
          options: item.options
        };
        return ffc;
      });
    });
  }

  onSubmit() {
    console.log(this.model);
  }
}
