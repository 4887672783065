import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthAction } from '../../auth/store/auth.actions';
import { AuthState } from '../../auth/store/auth.store';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    @Select(AuthState.isAuthenticated)
    member$: Observable<any>;

    isAuthenticated = false;

    constructor(private authStore: Store) {}

    ngOnInit() {
        this.member$.subscribe(
            (isAuthenticated) => (this.isAuthenticated = isAuthenticated)
        );
    }

    login() {
        this.authStore.dispatch(new AuthAction.Login()).subscribe();
    }

    logout() {
        this.authStore.dispatch(new AuthAction.Logout()).subscribe();
    }
}
