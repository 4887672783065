import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { SessionService } from '../../../session.service';

@Component({
    selector: 'standard-session-data',
    templateUrl: './session-data.component.html',
    styleUrls: ['./session-data.component.scss'],
})
export class SessionDataComponent implements OnInit, OnChanges, OnDestroy {
    @Input() session: any;
    @Input() isAuth = false;

    @Output() login = new EventEmitter<void>();

    memberIsAttending: boolean;
    subscriptions: Subscription[] = [];
    speakers: any[];
    mentors: any[];

    constructor(
        private _sessionService: SessionService,
        private _authService: AuthService
    ) {}

    ngOnInit() {}

    ngOnChanges() {
        this.subscriptions.push(
            this._authService.getAuthorizedMember().subscribe((m) => {
                this.subscriptions.push(
                    this._sessionService
                        .getAttendeeSessions(m._id)
                        .subscribe((sessions) => {
                            this.memberIsAttending = sessions.some(
                                (session) => {
                                    return session._id === this.session._id;
                                }
                            );
                        })
                );
            })
        );

        this.speakers = this.session.speakers.filter(
            (speaker) => speaker.role === 'Speaker'
        );
        this.mentors = this.session.speakers.filter(
            (speaker) => speaker.role === 'Mentor'
        );
    }

    attend() {
        this.subscriptions.push(
            this._sessionService
                .attendSession(this.session._id)
                .subscribe((a) => {
                    this.memberIsAttending = true;
                })
        );
    }

    unattend() {
        this.subscriptions.push(
            this._sessionService
                .unattendSession(this.session._id)
                .subscribe((x) => {
                    this.memberIsAttending = false;
                })
        );
    }

    emitLogin() {
        this.login.emit();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }
}
