import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, replace = '_', _with = ' '): string {
    return value?.replace(new RegExp(replace, 'g'), _with);
  }

}
