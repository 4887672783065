export namespace AuthAction {
  export class Login {
    static readonly type = '[Auth] Login';
  }

  export class Logout {
    static readonly type = '[Auth] Logout';
  }

  export class GetAuthorizedMember {
    static readonly type = '[Auth] GetAuthorizedMember';
  }
}
