import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { PlenarySession, StrapiSession } from '../../../../../../types';
import { Observable } from 'rxjs';
import { AuthState } from 'src/app/auth/store/auth.store';

@Component({
    selector: 'session-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['../session-tools.component.scss', 'chat.component.css'],
})
export class ChatComponent implements OnInit {
    @Input()
    session: StrapiSession;

    @ViewChild('chatFrame') chatFrame: ElementRef<HTMLIFrameElement>;

    @Select(AuthState)
    member$: Observable<any>;

    constructor() {}

    ngOnInit(): void {}

    @HostListener('window:message', ['$event'])
    handleZoomFrameMessage(event: MessageEvent) {
        // IMPORTANT! read these security notes before fucking around in here
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
        if (!event.origin.startsWith('https://chat.plenary.foxlab.io')) return;

        const target = this.chatFrame.nativeElement.contentWindow;
        if (event.data === 'ready') {
            this.member$.subscribe((m) => {
                const matrix = JSON.parse(m.auth.attributes.matrix);
                const args = {
                    user: matrix.user_id,
                    passwd: matrix.password,
                };
                target.postMessage(
                    {
                        cmd: 'login',
                        args,
                    },
                    '*'
                );
            });
        } else if (event.data === 'initialized') {
            target.postMessage(
                {
                    cmd: 'switchRoom',
                    args: this.session.matrixRoom,
                },
                '*'
            );
        }
    }
}
