<div class="schedule">
  <!-- <img class="background"
      src="https://res.cloudinary.com/ysi/image/upload/c_fit/v1599499036/testevent/spaceship_schedule.png"
      alt="monitor"
    /> -->
  <div class="session-list">
    <!-- <h5 class="schedule-title card-title">Schedule</h5> -->
    <ng-container *ngIf="currentSessions$ | async as current">
      <div
        class="session-box"
        *ngFor="let session of current.sessions; let i = index"
      >
        <div class="session-content">
          <div class="session-header">
            <span class="session-title">
              <b>
                {{ session.Title }}
              </b>
            </span>
            <span class="dark-grey time">
              {{ session.start_time | date: "MMM dd, HH:mm" }}
            </span>
          </div>
          <div class="session-bottom">
            <ng-container *ngIf="session.Location.startsWith('Constellation')">
              <span>{{ session.Location.split('_').slice(1).join(' ') }}</span>

              <!-- <a
                class="join-btn"
                [routerLink]="['/questions']"
                [queryParams]="{
                  constellation: session.constellation._id,
                  session: session._id
                }"
                queryParamsHandling="merge"
              >
                Join
              </a> -->
              <div>
                <span class="active-session" *ngIf="session.state.active">
                  Active!
                </span>
                <a
                  class="session-list-btn join-btn"
                  [routerLink]="['session', session._id]"
                >
                  View
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="!session.Location.startsWith('Constellation')">
              <span> </span>
              <div>
                <span class="active-session" *ngIf="session.state.active">
                  Active!
                </span>
                <a
                  class="session-list-btn join-btn"
                  [routerLink]="['session', session._id]"
                >
                  View
                </a>
              </div>
            </ng-container>
          </div>
        </div>
        <!-- <span class="seperator-line" *ngIf="i !== current.count - 1"></span> -->
      </div>
      <div class="session-paginator">
        <paginator
          (page)="changePage($event)"
          [count]="current.count"
          [limit]="limit"
        >
        </paginator>
      </div>
    </ng-container>
  </div>
</div>
