import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { switchMap, map, tap, pairwise, startWith } from 'rxjs/operators';


@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
  @Output()
  page = new EventEmitter<number>();

  currentPage = 0;
  dots: number[] = [];

  @Input()
  limit = 1;

  _count = 0;
  @Input() set count(c: number) {
    this._count = c;
    this.dots = new Array(Math.floor(c / this.limit)).map((e, i) => i);
  }

  get count() {
      return this._count;
  }

  paginator$ = new BehaviorSubject<number>(0);

  constructor() { }

  ngOnInit(): void {
    this.paginator$.subscribe(page => {
      this.page.emit(page);
    });
  }

  next() {
    this.jump(1);
  }

  previous() {
    this.jump(-1);
  }

  jump(jump: number) {
    this.currentPage += jump;
    this.paginator$.next(this.currentPage);
  }
}
