import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SessionFormComponent } from './session-form/session-form.component';
import { SessionHomeComponent } from './session-home/session-home.component';
import { StandardSessionComponent } from './session-home/standard-session/standard-session.component';
import { SessionDataComponent } from './session-home/standard-session/session-data/session-data.component';
import { SessionToolsComponent } from './session-home/standard-session/session-tools/session-tools.component';
import { SessionScheduleComponent } from './session-schedule/session-schedule.component';
import { SessionService } from './session.service';
import { ChatUrlPipe } from './chat-url.pipe';
import { SessionModeratorComponent } from './session-home/standard-session/session-moderator/session-moderator.component';
import { PaginatorComponent } from './session-schedule/paginator/paginator.component';
import { ChatComponent } from './session-home/standard-session/session-tools/chat/chat.component';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { SessionUrlPrefixPipe } from './session-url-prefix.pipe';

@NgModule({
    declarations: [
        SessionFormComponent,
        SessionHomeComponent,
        SessionScheduleComponent,
        ChatUrlPipe,
        StandardSessionComponent,
        SessionDataComponent,
        SessionToolsComponent,
        SessionModeratorComponent,
        PaginatorComponent,

        ChatComponent,
        SessionUrlPrefixPipe,
    ],
    imports: [CommonModule, ReactiveFormsModule, RouterModule, PipeModule],
    providers: [SessionService],
    exports: [
        SessionFormComponent,
        SessionHomeComponent,
        SessionScheduleComponent,
        ChatComponent,
        SessionUrlPrefixPipe,
    ],
})
export class SessionsModule {}
