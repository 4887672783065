import { NgModule } from '@angular/core';
import { EtherpadUrlPipe } from './etherpad-url.pipe';
import { LinebreakPipe } from './linebreak.pipe';
import { ReplacePipe } from './replace.pipe';
import { SliceWithDotsPipe } from './sliceWithDots.pipe';

@NgModule({
  imports: [],
  declarations: [
    LinebreakPipe,
    SliceWithDotsPipe,
    ReplacePipe,
    EtherpadUrlPipe,
  ],
  exports: [
    LinebreakPipe,
    SliceWithDotsPipe,
    ReplacePipe,
    EtherpadUrlPipe,
  ],
})
export class PipeModule { }
