<div class="">
  <div>
    <div *ngIf="!isAuthenticated; else logoutTemplate">
      <button (click)="login()">Login</button>
    </div>

    <ng-template #logoutTemplate>
      <button (click)="logout()">Logout</button>
    </ng-template>
  </div>
</div>
