import {
    AfterViewChecked,
    Component,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { PlenarySession, StrapiSession } from '../../../../../types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Split from 'split-grid';
import { AuthState } from 'src/app/auth/store/auth.store';
import { SessionService } from '../../../session.service';

@Component({
    selector: 'standard-session-tools',
    templateUrl: './session-tools.component.html',
    styleUrls: ['./session-tools.component.scss'],
})
export class SessionToolsComponent
    implements OnInit, AfterViewChecked, OnChanges
{
    @Input() session: StrapiSession;
    video = false;
    collab = false;
    chat = false;
    gutter = false;
    columns: string;
    areas: string;

    @Select(AuthState.isModerator)
    isModerator$: Observable<boolean>;

    state$ = this._sessionService
        .sessionState()
        .pipe(tap((s) => this.ngOnChanges()));

    constructor(private _sessionService: SessionService) {}

    ngOnInit() {
        Split({
            columnGutters: [
                {
                    track: 1,
                    element: document.querySelector('#gutter'),
                },
            ],
            minSize: 100,
        });
        this.ngOnChanges();
    }

    ngAfterViewChecked() {}

    ngOnChanges() {
        this.gutter = false;
        if (this.session.zoom && this.session.state.zoomActive) {
            this.video = true;
        } else {
            this.video = false;
        }
        if (this.session.etherpad && this.session.state.padActive) {
            this.collab = true;
        } else {
            this.collab = false;
        }
        if (this.session.matrixRoom && this.session.state.chatActive) {
            this.chat = true;
        } else {
            this.chat = false;
        }
        this.compose();
    }

    compose() {
        if (this.collab && this.chat) {
            this.gutter = true;
            this.columns = '1fr 7px 1fr';
            this.areas = '"collab gutter chat"';
        } else if (this.collab && !this.chat) {
            this.gutter = false;
            this.columns = '1fr';
            this.areas = '"collab"';
        } else if (!this.collab && this.chat) {
            this.gutter = false;
            this.columns = '1fr';
            this.areas = '"chat"';
        }
    }
}
