import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SessionService } from '../session.service';
import { switchMap, map } from 'rxjs/operators';
import { PlenarySession, StrapiSession } from '../../../types';

const slicer = (page = 0, limit = 7) =>
    map((sessions: (PlenarySession & StrapiSession)[]) =>
        // put this in api:
        ({
            count: sessions.length,
            sessions: sessions.slice(page * limit, page * limit + limit),
        })
    );

@Component({
    selector: 'session-schedule',
    templateUrl: './session-schedule.component.html',
    styleUrls: ['./session-schedule.component.scss', '../../base.scss'],
})
export class SessionScheduleComponent implements OnInit {
    @Input()
    kind: 'wg' | 'governance' | 'constellation' | 'social' | any;

    @Input()
    constellationId: string;

    @Input()
    wgUrl: string;

    @Output()
    sessionSelected = new EventEmitter<any>();

    @Input()
    limit = 3;

    currentPage = new BehaviorSubject(0);
    currentSessions$ = this.currentPage.pipe(
        switchMap((page) =>
            this.kind === 'wg'
                ? this._sessionService
                      .getSessionsForWg(this.wgUrl)
                      .pipe(slicer(page, this.limit))
                : this._sessionService
                      .getSessions(this.kind)
                      .pipe(slicer(page, this.limit))
        )
    ) as Observable<{
        sessions: (PlenarySession & StrapiSession)[];
        count: number;
    }>;

    constructor(private _sessionService: SessionService) {}

    ngOnInit() {}

    select(session: any) {
        this.sessionSelected.emit(session);
    }

    changePage(page: number) {
        this.currentPage.next(page);
    }
}
