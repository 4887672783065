<div class="ss-content">
  <div id="hide-show" class="btn" (click)="toggleSessionData()">
    <div class="absolute-center">
      <span
        class="arrow bottom"
        [ngClass]="showSessionData ? 'show' : 'hide'"
      ></span>
    </div>
    <!-- {{ showSessionData ? "Hide" : "Show" }} info -->
  </div>
  <a class="cancel-btn session-cancel" routerLink="../../">&#10005;</a>
  <div class="session-main-info">
    <div class="session-header">
      <h2>{{ session.Title }}</h2>
      <div class="time-wg">
        <div class="time-box">
          <!-- <h5>Time:</h5> -->
          <p>
            <strong>
              {{ session.start_time | date: 'HH:mm' }} -
              {{ session.end_time | date: 'HH:mm' }},
              {{ session.start_time | date: 'EEEE, d MMMM' }}
            </strong>
          </p>
        </div>
        <div class="wg-title-icon">
          <strong *ngIf="locationNameMap[session.Location]"
            >Location: {{ locationNameMap[session.Location] }}</strong
          >
          <strong
            *ngIf="
              session.Location === 'Community' ||
              session.Location === 'Social_island'
            "
            >Location: {{ session.Location | replace: '_':' ' }}</strong
          >
          <!-- <img
            src="https://res.cloudinary.com/ysi/image/upload/v1603970605/plenary/small-icons/boat.svg"
            alt=""
          /> -->
        </div>
      </div>
    </div>

    <standard-session-data
      [session]="session"
      [isAuth]="isAuthenticated"
      *ngIf="showSessionData"
      (login)="login()"
    ></standard-session-data>
  </div>

  <ng-container *ngIf="isModerator$ | async">
    <standard-session-moderator
      [session]="session"
      (toolsToggled)="onToolToggled($event)"
    ></standard-session-moderator>
  </ng-container>
  <standard-session-tools
    *ngIf="
      (session.type !== 'Project_Brainstorm' &&
        (session.kind !== 'constellation' && isModerator$ | async)) ||
      (session.kind !== 'constellation' && session.state.active && joined)
    "
    [session]="session"
  ></standard-session-tools>

  <ng-container *ngIf="!joined">
    <div id="session-state" *ngIf="session.state.active">
      <button
        *ngIf="session.constellation"
        (click)="!isAuthenticated && login()"
        [routerLink]="['/questions']"
        [queryParams]="{
          constellation: session.constellation,
          session: session._id
        }"
        queryParamsHandling="merge"
      >
        Join now
      </button>
      <button
        *ngIf="!session.constellation"
        (click)="isAuthenticated ? join() : login()"
      >
        Join now
      </button>
      <p>
        <strong>This session has begun - join us now!</strong>
      </p>
    </div>
    <div id="session-state" *ngIf="!session.state.active">
      <h5>
        <strong>
          This session is <strong class="fat">not yet active!</strong> Once the
          session is active, you'll be able to join!
        </strong>
      </h5>
    </div>
  </ng-container>

  <!-- 5f9be621cdf2609a6a416e89 -->
  <!-- *ngIf="isModerator$ | async" -->
  <!-- <bucket-file-upload
    class="drag-drop"
    #uploader
    [multi]="true"
    [showUpload]="true"
    [bucket]="'plenary'"
    [isPublic]="true"
    [folder]="'sessions'"
    [sessionId]="session._id"
  >
  </bucket-file-upload> -->
</div>
