import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlenarySession } from '../../../types';
import { Observable } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { SessionService } from '../session.service';

@Component({
    selector: 'session-home',
    templateUrl: './session-home.component.html',
})
export class SessionHomeComponent implements OnInit, OnDestroy {
    session$: Observable<PlenarySession>;

    constructor(
        private _route: ActivatedRoute,
        private _sessionService: SessionService
    ) {}

    ngOnInit() {
        this.session$ = this._route.params.pipe(
            filter((p) => p.sessionId),
            tap((p) => this._sessionService.enterSession(p.sessionId)),
            switchMap((p) => this._sessionService.getSession(p.sessionId)),
            finalize(() => this._sessionService.exitSession())
        );

        this._sessionService.reconnect().subscribe(() => {
            this._route.params.subscribe((p) => {
                console.log('reconnecting to: ' + p.sessionId);
                this._sessionService.enterSession(p.sessionId);
            });
        });
    }

    ngOnDestroy() {}
}
