<div id="session-moderation">
  <div class="tools-explenation-wrapper">
    <div class="w-100">
      <div class="moderation-header">
        <h4>Moderation panel</h4>
      </div>
      <div class="moderation-tools">
        <ng-container *ngIf="!session.Location.startsWith('Constellation')">
          <div class="moderation-tools-wrapper">
            <div class="moderation-tool" *ngIf="session.zoom">
              <h5>Zoom</h5>
              <label class="switch">
                <input
                  [checked]="session.state.zoomActive"
                  type="checkbox"
                  (click)="toggle('zoomActive')"
                />
                <span class="slider"></span>
              </label>
            </div>
            <div class="moderation-tool" *ngIf="session.etherpad">
              <h5>Document Collaboration</h5>
              <label class="switch">
                <input
                  [checked]="session.state.padActive"
                  type="checkbox"
                  (click)="toggle('padActive')"
                />
                <span class="slider"></span>
              </label>
            </div>

            <div class="moderation-tool" *ngIf="session.matrixRoom">
              <h5>Text Chat</h5>
              <label class="switch">
                <input
                  [checked]="session.state.chatActive"
                  type="checkbox"
                  (click)="toggle('chatActive')"
                />
                <span class="slider"></span>
              </label>
            </div>
          </div>
          <div class="start-stop-wrapper">
            <div>
              <button
                class="btn-success"
                *ngIf="!session.state.active"
                (click)="toggle('active')"
              >
                Start Session
              </button>
              <button
                class="btn-danger"
                *ngIf="session.state.active"
                (click)="toggle('active')"
              >
                Stop Session
              </button>
            </div>
            <h4 *ngIf="!session.state.active">
              Please note: Your session is
              <strong class="red">not yet active</strong>, and members can't use
              the tools below - only moderators can see them. Once you
              <strong>Start Session</strong>, members will be able to join.
            </h4>
            <h4 *ngIf="session.state.active">
              Please note: Your session
              <strong class="green">is active</strong>, and members can use the
              tools below.
            </h4>
          </div>

          <!-- <button *ngIf="session.etherpad" (click)="toggle('padActive')">
            <span *ngIf="session.state.padActive"
              >Hide Document Collaboration</span
            ><span *ngIf="!session.state.padActive"
              >Show Document Collaboration</span
            >
          </button> -->
          <!-- <button *ngIf="session.zoom" (click)="toggle('zoomActive')">
            <span *ngIf="session.state.zoomActive">Hide Zoom</span
            ><span *ngIf="!session.state.zoomActive">Show Zoom</span>
          </button> -->
          <!-- <button *ngIf="session.matrixRoom" (click)="toggle('chatActive')">
            <span *ngIf="session.state.chatActive">Hide Text Chat</span
            ><span *ngIf="!session.state.chatActive">Show Text Chat</span>
          </button> -->
        </ng-container>
        <ng-container *ngIf="session.Location.startsWith('Constellation')">
          <button
            [routerLink]="['/questions']"
            [queryParams]="{
              constellation: session.constellation,
              session: session._id
            }"
            queryParamsHandling="merge"
          >
            Go to session
          </button>
          <p>
            As a moderator, you can enter a session before it has been
             activated by using the Go to Session button. This allows you to prepare your session prior to your session
              - but keep in mind that regular members can only join once the session has been started!</p>
        </ng-container>
      </div>
    </div>
    <div class="explenatin-text">
      <p>
        Need support, help, or assistance? Click
        <a class="help-link" href="https://discord.gg/de5WSBtyWG" target="_blank"
          >HERE</a
        >
        to join the Moderator support chat!
      </p>
    </div>
  </div>
</div>
