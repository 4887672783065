import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linebreak',
})
export class LinebreakPipe implements PipeTransform {
  transform(str: string): string {
    const regEx = /\n/g;
    return str?.replace(regEx, '<br>');
  }
}
