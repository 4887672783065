import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'etherpadUrl'
})
export class EtherpadUrlPipe implements PipeTransform {

  constructor(
    private _sanitizer: DomSanitizer,
  ) { }

  transform(padId: string) {
    return this._sanitizer
      .bypassSecurityTrustResourceUrl(`https://pad.plenary.foxlab.io/p/${padId}`);
  }

}
