<form [formGroup]="eventForm" class="session-create-form">
  <a class="cancel-btn" routerLink="..">&#10005;</a>
  <h1>Create new session</h1>
  <h6>
    Create a new Session by filling in the form below
  </h6>

  <div class="flex">
    <div class="half">
      <div>
        <p>Session title:</p>
        <input class="full" type="text" formControlName="title" />
      </div>
      <div>
        <p>Session description:</p>
        <textarea
          class="full textarea"
          type="text"
          formControlName="description"
        ></textarea>
      </div>

      <div *ngIf="kind === 'constellation'">
        <p>Select which Constellation this Session will take place in:</p>
        <select class="form-control" [formControl]="constellationControl">
          <option
            *ngFor="let constellation of constellations"
            [value]="constellation._id"
          >
            {{ constellation.name }}
          </option>
        </select>
      </div>
      <div class="flex-align-center">
        <div class="select-time">
          <p>Select start time:</p>
          <div><input type="time" id="appt" formControlName="start" /></div>
        </div>

        <div class="select-time">
          <p>Select end time:</p>
          <div><input type="time" id="appt" formControlName="end" /></div>
        </div>
      </div>

      <!-- <h4>Tools:</h4> -->
      <h6>Select which YSI Tools you wish to add to your session</h6>
      <!-- <div>
       <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
       <label for="vehicle1">Constellation</label>
       </div> -->
      <div class="checkboxdiv">
        <input type="checkbox" id="etherpad" formControlName="etherpad" />
        <label for="etherpad">Document collaboration</label>
      </div>
      <div class="checkboxdiv">
        <input type="checkbox" id="zoom" formControlName="zoom" />
        <label for="zoom">Webcam meeting</label>
      </div>
      <div class="checkboxdiv">
        <input type="checkbox" id="chatroom" formControlName="riot" />
        <label for="chatroom">Chatroom</label>
      </div>
      <button class="btn btn-primary submit-new-session" (click)="onSubmit()">
        Submit
      </button>
    </div>

  </div>
</form>
