import { Injectable } from '@angular/core';
import { SocketEventMap, SocketType } from '../../types';
import { fromEvent } from 'rxjs';
import io from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SocketClient {
    private socket: SocketIOClient.Socket;
    private moderatorSocket: SocketIOClient.Socket;

    connect() {
        this.socket = io(environment.sockets, {
            transports: ['websocket'],
        });

        this.moderatorSocket = io(environment.sockets + '/moderator', {
            transports: ['websocket'],
        });
    }

    on<K extends keyof SocketEventMap>(event: K, type?: SocketType) {
        if (type === undefined || type == 'user') {
            return fromEvent<SocketEventMap[K]>(this.socket, event);
        } else if (type == 'moderator') {
            return fromEvent<SocketEventMap[K]>(this.moderatorSocket, event);
        }
    }

    emit<K extends keyof SocketEventMap>(
        event: K,
        data: SocketEventMap[K],
        type?: SocketType
    ) {
        if (type === undefined || type == 'user') {
            this.socket.emit(event as string, data);
        } else if (type == 'moderator') {
            this.moderatorSocket.emit(event as string, data);
        }
    }
}
