import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Question, Phrase } from '../models/question.model';
import { Constellation } from '../models/constellation.model';
import {
    GraphNode,
    GraphLink,
    GraphData,
} from '../questions-home/questions-graph/questions-graph-core/constellation-to-graph-mapper.d';
import { SessionService } from 'src/app/sessions/session.service';

@Injectable({ providedIn: 'root' })
export class QuestionStateService {
    constructor(private _sessionService: SessionService) {}

    private readonly _question$ = new BehaviorSubject<Question>(null);
    private readonly _questions$ = new BehaviorSubject<Question[]>([]);

    private readonly _researchInterestId$ = new BehaviorSubject<string>(null);

    private readonly _constellation$ = new BehaviorSubject<Constellation>(null);
    private readonly _constellations$ = new BehaviorSubject<Constellation[]>(
        []
    );
    private readonly _isEditing$ = new BehaviorSubject<string>(null);
    private readonly _sortingAlgo$ = new BehaviorSubject<Sorting>(
        'most-recent'
    );
    private readonly _filterAlgo$ = new BehaviorSubject<Filter>('all');

    private readonly _scrollToQuestion$ = new BehaviorSubject<string>(null);
    private readonly _graphData$ = new BehaviorSubject<GraphData>(null);

    private readonly _tools$ = new BehaviorSubject<Tools>({
        chat: false,
        vote: false,
    });

    /** -------------------------- TOOLS GETTERS SETTERS ---------------------------------- */
    public set tools(tools: Tools) {
        this._tools$.next(tools);
    }

    public get tools$() {
        return this._tools$.asObservable();
    }

    /** -------------------------- GRAPHDATA GETTERS SETTERS ---------------------------------- */

    public set graphData(graphData: {
        nodes: GraphNode[];
        links: GraphLink[];
    }) {
        this._graphData$.next(graphData);
    }

    public get graphData$() {
        return this._graphData$.asObservable();
    }
    /** -------------------------- EDITING STATUS GETTERS SETTERS ---------------------------------- */

    public set isEditing(id: string) {
        this._isEditing$.next(id);
    }

    public get isEditing$() {
        return this._isEditing$.asObservable();
    }

    public set sortingAlgo(algo: Sorting) {
        this._sortingAlgo$.next(algo);
    }

    public get sortingAlgo$() {
        return this._sortingAlgo$.asObservable();
    }

    public set filterAlgo(algo: Filter) {
        this._filterAlgo$.next(algo);
    }

    public get filterAlgo$() {
        return this._filterAlgo$.asObservable();
    }
    /** -------------------------- SESSION GETTERS SETTERS ---------------------------------- */

    public set scrollToQuestion(questionId: string) {
        this._scrollToQuestion$.next(questionId);
    }

    public get scrollToQuestion$() {
        return this._scrollToQuestion$.asObservable();
    }

    /** -------------------------- QUESTION GETTERS SETTERS ---------------------------------- */

    /** Single question setter */
    public set researchInterestId(interestID: string) {
        this._researchInterestId$.next(interestID);
    }

    /** Single question getter */
    public get researchInterestId$() {
        return this._researchInterestId$.asObservable();
    }

    /** Single question setter */
    public set question(question: Question) {
        this._question$.next(question);
    }

    /** Single question getter */
    public get question$() {
        return this._question$.asObservable();
    }

    /** question array setter */
    public set questions(questions: Question[]) {
        console.warn("DON'T USE THIS!");
        this._questions$.next(questions);
    }

    /** question array getter */
    public get questions$() {
        console.warn("DON'T USE THIS!");
        return this._questions$.asObservable();
    }

    /** -------------------------- CONSTELLATIONS GETTERS SETTERS ---------------------------------- */

    public set constellation(constellation: Constellation) {
        // console.log('questions: ', questions);
        this._constellation$.next(constellation);
    }

    public get constellation$() {
        return this._constellation$.asObservable();
    }

    public set constellations(constellations: Constellation[]) {
        // console.log('questions: ', questions);
        this._constellations$.next(constellations);
        const currentConstellation = this._constellation$.value;
        if (currentConstellation) {
            const newConstellation = constellations.find(
                (c) => c._id === currentConstellation._id
            );
            const a = JSON.stringify(currentConstellation);
            const b = JSON.stringify(newConstellation);
            if (a !== b) {
                this.constellation = newConstellation;
                console.debug(
                    'NOW updating constellation!!!!!',
                    newConstellation
                );
                //console.debug('current Constellation:', currentConstellation);
                //console.debug('new Constellation:', newConstellation);
            } else {
                //console.debug('not updating the current constellation');
            }
        }
    }

    public constellationsMD5$ = new BehaviorSubject<string>('');

    public get constellations$() {
        return this._constellations$.asObservable();
    }

    /// This will update the hole constellation
    public updateConstellation(updatedConstellation: Constellation) {
        console.log('updatedConstellation: ', updatedConstellation);
        //Find the correct question to update
        const constellations = [...this._constellations$.value];
        const index = constellations.findIndex(
            (c) => c._id === updatedConstellation._id
        );
        constellations[index] = { ...updatedConstellation };
        this.constellations = constellations;
    }

    public updateQuestion(updatedQuestion: Question) {
        //Find the correct question to update
        const qindex = this._constellation$.value.questions.findIndex(
            (q) => q._id === updatedQuestion._id
        );
        const constellation = this._constellation$.value;
        constellation.questions[qindex] = updatedQuestion;
        constellation.questions = constellation.questions.filter(
            (q) => !q.isDeleted
        );
        const constellations = this._constellations$.value;
        const cindex = constellations.findIndex(
            (c) => c._id === updatedQuestion._id
        );
        constellations[cindex] = { ...constellation };
        this._constellations$.next(constellations);
        this._constellation$.next(constellation);
    }

    public addQuestion(newQuestion: Question) {}

    /** -------------------------- QUESTION ACTIONS ---------------------------------- */
    public init() {
        // this._httpService.getAll().subscribe(questions => {
        //   this.questions = questions;
        // });
    }

    // public add(question: Question) {
    //   this._questions$.next([...this._questions$.value, question]);
    // }

    public update(updatedQuestion: Question) {
        const index = this._questions$.value.findIndex(
            (q) => q._id === updatedQuestion._id
        );
        if (index > -1) {
            const questionArr: Question[] = this._questions$.value;
            questionArr[index] = Object.assign(
                {},
                questionArr[index],
                updatedQuestion
            );
            this._questions$.next(questionArr);
        }
    }

    public delete(question) {
        console.warn("DON'T USE THIS!");
        const index = this._questions$.value.findIndex(
            (q) => q._id === question._id
        );
        if (index > -1) {
            const questionArr: Question[] = this._questions$.value;
            questionArr.splice(index, 1);
            this._questions$.next(questionArr);
        }
    }

    private _state: any = {
        isVisible: true,
        allowVoting: false,
        allowFavorites: false,
        allowAddPhrase: false,
        allowAddQuestion: false,
        allowMovePhrasing: false,
        allowNewQuestionFromPhrasing: false,
        allowDeletePhrase: false,
    };

    private _state$ = new BehaviorSubject(this._state);
    public get state$() {
        return this._state$.asObservable();
    }
}

export interface Tools {
    chat: boolean;
    vote: boolean;
}

export type Sorting = 'most-recent' | 'most-favorites' | 'most-likes';
export type Filter = 'only-top' | 'all';
