<section class="join-session-content">
  <div id="session-info">
    <div class="description">
      <div class="session-description">
        <h5>
          <!-- Session type: -->
          <strong>{{ session.type | replace: "_":" " }}</strong>
        </h5>

        <div class="moderator-mentor">
          <div class="moderators" *ngIf="session.moderators.length > 0">
            <h5>Moderated by:</h5>
            <p class="moderator" *ngFor="let moderator of session.moderators">
              {{ moderator.first_name }} {{ moderator.last_name }}
            </p>
          </div>
          <div class="mentors">
            <h5 *ngIf="mentors.length > 0">Mentors:</h5>
            <p class="mentor" *ngFor="let mentor of mentors">
              {{ mentor.firstname }} {{ mentor.lastname }}
              <span class="title-affiliation">
                | {{ mentor.title }},
                {{ mentor.affiliation }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="text-presentors">
        <p>
          {{ session.Description }}
        </p>

        <div id="session-presenters">
          <h5 *ngIf="session.presenters.length || speakers.length">
            Presentations by:
          </h5>

          <div id="presenters">
            <p *ngFor="let speaker of speakers">
              {{ speaker.firstname }} {{ speaker.lastname }}
              <span class="title-affiliation"
                >| {{ speaker.title }}, {{ speaker.affiliation }}</span
              >
            </p>
            <p *ngFor="let presenter of session.presenters">
              {{ presenter.first_name }} {{ presenter.last_name }}
              <span class="title-affiliation"
                >| {{ presenter.title }}, {{ presenter.affiliation }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- this part needs to be moves into standard-session -->
  <div class="session-data">
    <div id="sign-up">
      <button
        class="not-signed-up"
        *ngIf="!memberIsAttending"
        (click)="isAuth ? attend() : emitLogin()"
      >
        Add to my sessions
      </button>
      <button class="signed-up" *ngIf="memberIsAttending" (click)="unattend()">
        Added to my sessions
      </button>

      <div class="description-text">
        <p>
          <strong>
            If you sign up for this session it will be added to your "My
            Sessions" list.
          </strong>
        </p>
      </div>
    </div>
  </div>
</section>
