import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ICmsContent } from './cms.model';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  constructor(private http: HttpClient) {}

  getCollection<T>(id: string) {
    return this.http.get<ICmsContent & T>(`${environment.cms}/${id}`);
  }

  getSingle<T>(id: string) {
    return this.http.get<ICmsContent & T>(`${environment.cms}/${id}`);
  }

  uploadFile(file: File) {
    const strapiUrl = 'http://localhost:4005';
    const sessionId = '5f88031013f0675889bebaa0';

    var formData: FormData = new FormData();

    formData.append(
      'data',
      JSON.stringify({
        refId: sessionId,
        ref: 'sessions',
        field: 'Resources',
      })
    );

    formData.append('files.resources', file, file.name);

    return this.http.put(`${strapiUrl}/sessions/${sessionId}`, formData);
  }
}
