<div class="session-video">
  <div class="welcome-text">
    <h4>Welcome to the YSI Toolbox!</h4>
    <p>
      Here you will find tools to help you communicate and collaborate in real
      time. Your session moderator can open and close the different tools - such
      as webinar meeting, document collaboration or text chat - so all tools may
      not be available at all times
    </p>
  </div>
  <ng-container *ngIf="state$ | async as state">
    <div id="zoom-video" *ngIf="state.zoomActive">
      <h5>Zoom</h5>
      <p>
        Join the Zoom webinar for this session by clicking the 'Join' button
        below!
      </p>
      <div id="zoom-urls">
        <div class="d-flex">
          <a [href]="session.zoom.joinUrl" target="_blank">Join meeting</a>
          <a
            [href]="session.zoom.startUrl"
            target="_blank"
            *ngIf="isModerator$ | async"
            >Start meeting</a
          >
        </div>
        <!-- <p>Meeting password: {{ session.zoom.password }}</p> -->
      </div>
    </div>
  </ng-container>
</div>

<div
  #grid
  class="colab-chat-wrapper"
  [style.grid-template-columns]="columns"
  [style.grid-template-areas]="areas"
>
  <div id="session-collab" *ngIf="collab">
    <div class="description-text">
      <h5>Document collaboration</h5>
      <p>
        This is a real time collaborative document! Anything you write will
        instantly be seen by other participants. You can use this document to
        collaborate on text with other participants during this session.
      </p>
    </div>

    <iframe
      *ngIf="session.etherpad"
      [src]="session.etherpad | etherpadUrl"
    ></iframe>
  </div>

  <div id="gutter" [style.display]="gutter ? 'initial' : 'none'"></div>

  <div id="session-chat" *ngIf="chat">
    <div class="description-text">
      <h5>Text chat</h5>
      <p>
        This is a real time chat, containing you and all other active
        participants of this session. Use it to communicate via text with
        everyone in the session!
      </p>
    </div>
    <session-chat [session]="session" *ngIf="chat"></session-chat>
  </div>
</div>
