import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import addHours from 'date-fns/addHours';
import { ConstellationHttpService } from 'src/app/questions/services/constellation-http.service';

import { SessionService } from '../session.service';

@Component({
    selector: 'session-form',
    templateUrl: './session-form.component.html',
    styleUrls: ['./session-form.component.scss', '../../base.scss'],
})
export class SessionFormComponent implements OnInit {
    @Input()
    kind: 'wg' | 'constellation' | 'governance';

    constellations = [];
    constellationControl = this._fb.control('');

    wgUrl: string;

    eventForm = this._fb.group({
        title: [''],
        description: [''],
        start: [''],
        end: [''],
        etherpad: [false],
        zoom: [false],
        riot: [false],
    });

    constructor(
        private _fb: FormBuilder,
        private _route: ActivatedRoute,
        private _eventService: SessionService,
        private _constellationService: ConstellationHttpService
    ) {}

    ngOnInit() {
        if (!this.kind)
            this._route.data.subscribe((d) => {
                this.kind = d.kind;
            });

        if (this.kind === 'constellation') {
            // console.log('Session form')
            this._constellationService
                .getAll()
                .subscribe((cs) => (this.constellations = cs.constellations));
        }

        if (this.kind === 'wg')
            this._route.parent.params.subscribe((p) => (this.wgUrl = p.wgUrl));
    }

    onSubmit() {
        const partialEvent = this.eventForm.value;

        const start = new Date();
        const end = addHours(start, 1);

        const constellation = this.constellationControl.value;

        const event = {
            ...partialEvent,
            kind: this.kind,
            start,
            end,
            constellation,
            wg: this.wgUrl,
        };

        this._eventService
            .createSession(event)
            .subscribe((e) => console.log(e));
    }
}
