<!-- <form [formGroup]="selectFormForm">
  <select formControlName="countryControl">
    <option [value]="strapiForm" *ngFor="let form of allForms">
      {{ form.name }}</option
    >
  </select>
</form> -->

<div *ngIf="strapiForm" class="container">
  <h1>{{ strapiForm.name }}</h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <button type="submit" class="btn btn-default">
      {{ strapiForm.submitButtonText }}
    </button>
  </form>
</div>
