import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { AuthAction } from './auth/store/auth.actions';
import { AuthState } from './auth/store/auth.store';
import { Observable } from 'rxjs';
import { SocketClient } from './socket/socket.client';
import { AuthService } from './auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    modalActive = false;
    mobile = false;
    route: string;

    @Select(AuthState)
    member$: Observable<any>;

    constructor(
        private authStore: Store,
        private _authService: AuthService,
        private socketService: SocketClient,
        private _router: Router,
        private _route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.member$.subscribe(/*v => console.log('appo', v)*/);

        this.authStore.dispatch(new AuthAction.GetAuthorizedMember()).subscribe(
            (out) => {},
            (err) => {
                console.error(err);
                // Handle error gracefully
            }
        );

        this.socketService.connect();

        this.socketService.on('notification').subscribe((message) => {
            console.log('notification:', message);
        });
    }

    @HostListener('window:resize', [])
    private onResize() {
        this.detectScreenSize();
    }

    ngAfterViewInit() {
        this.detectScreenSize();
    }

    private detectScreenSize() {
        if (!this.mobile && window.innerWidth <= 650) {
            this.mobile = true;
            this.route = this._router.url;
            this._router.navigate(['/m']);
        } else if (this.mobile && window.innerWidth > 650) {
            this.mobile = false;
            this._router.navigate([this.route]);
        }
    }

    onModalActivate() {
        this.modalActive = true;

        //this.renderer.addClass(this.document.body, 'modal-open');
    }

    onModalDeactivate() {
        this.modalActive = false;

        //this.renderer.removeClass(this.document.body, 'modal-open');
    }
}
